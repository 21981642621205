import * as React from 'react';
import {useState, useEffect} from 'react'
import './admin.scss'
import AdminMenu from '../../components/admin/AdminMenu'
import { motion } from "framer-motion";
import AdminHeader from '../../components/admin/AdminHeader'
import { useLocation } from 'react-router-dom';
import admin_settings from '../../components/admin/admin_settings'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { Formik, Form, Field } from 'formik';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../firebase/firebase";
import { v4 as uuidv4 } from 'uuid';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';


const CustomTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#E6B751',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: "Nunito !important",

    '& fieldset': {
      borderColor: '#E6B751',
      borderRadius: '10px',
      transition: '0.3s',
      fontFamily: "Nunito !important",
    },
    '&:hover fieldset': {
      borderColor: '#E6B751',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#E6B751',
    },
  },
});

const CustomSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    transition: "0.2s",

  '&.Mui-checked': {
    color: '#F4E2B9',
    transform: 'translateX(22px)',
    '& + .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: "#F4E2B9",
    },
  },
}
}))



const AdminEditEntity = () => {
  let location = useLocation()
  let current_page = []
  const currentPage = location.pathname
  const [progress, setProgress] = useState(0)

  admin_settings.admin_menu.forEach( section => 
    {
      section.links_list.forEach(link => {
        {
          if (currentPage.includes(link.url)){
            current_page = link
          }
        }
      })
    }
  )

  const uploadFiles = (file) => {
    if (!file) return;
	  let uuid_photo = uuidv4();
    const sotrageRef = ref(storage, `files/${uuid_photo}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
        });
      }
    );
  };


  return (
    <>
    <div className='admin-body'>

        <AdminMenu/>

        <div className='admin-main-layout'>
            <AdminHeader title={`Edit ${current_page.name}`} logged_user={"1mgavura@gmail.com"}/>
            <div className='admin-edit-fieldset'>
              <div className='form-group'>
                {current_page.columns.center_columns.map((item, index)=> (
                  <>
                  { (item.edit_visible && item.type == 'text') &&
                      <CustomTextField label={item.headerName} id="custom-css-outlined-input" style={{width: '300px'}}/>
                  }
                  { (item.edit_visible && item.type == 'file') &&
                      <Button
                      variant="contained"
                      style={{
                        width: '300px',
                        fontFamily: "Nunito", 
                        backgroundColor: "#E6B751", 
                        boxShadow: 'none',
                        borderRadius: '10px',
                        height: '40px'
                      }}
                      component="label"
                    >
                      Upload {item.headerName}
                      <input
                        type="file"
                        hidden
                      />
                    </Button>
                  }
                  { (item.edit_visible && item.type == 'checkbox') &&
                  <div style={{display: 'flex', alignItems: 'center', color: '#727272'}}>
                    <CustomSwitch sx={{color: "#E6B751"}}/>{item.headerName}
                  </div>
                  }
                  </>
                ))}
              </div>
              <div className="admin-edit-files-container">

              </div>
            </div>
        </div>
    </div>
    </>
  )
   
}

export default AdminEditEntity