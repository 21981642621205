import { Link } from '@mui/material';
import building from '../../assets/building.png'

const center_columns = [
    { 
      field: 'id', 
      headerName: 'ID', 
      width: 50, 
      edit_visible: false, 
      type: 'text', 
    },
    { 
      field: 'image', 
      headerName: 'Image', 
      width: 70, 
      edit_visible:true, 
      type: 'file',
      renderCell: (params) => {
          if (params.row.photo == null){
            return (
              <p>
                <img src={building} className='admin-table-image'/>
              </p>
            )
          } else {
            return(
              <img src={params.row.photo} className='admin-table-image'/>
            )
          }
          
      }
    },
    { 
      field: 'title',
      headerName: 'Title',
      width: 200,
      edit_visible:true,
      type: 'text', 
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 200,
      edit_visible:true,
      type: 'text'
      
    },
    { 
      field: 'address', 
      headerName: 'Adress', 
      width: 50, 
      edit_visible: true, 
      type: 'text', 
      hide: true,
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 150,
      edit_visible:true,
      type: 'text',
    },
    { 
      field: 'phone', 
      headerName: 'Phone number', 
      width: 50, 
      edit_visible: true, 
      type: 'text', 
      hide: true,
    },
    {
      field: 'tags',
      headerName: 'Tags',
      description: 'This column has a value getter and is not sortable.',
      width: 20,
      edit_visible:true,
      type: 'text',
      valueGetter: (params) =>
        `${params.row.tags.length || ''}`,
    },
    { 
      field: 'is_online', 
      headerName: 'Is online', 
      width: 50, 
      edit_visible: true, 
      type: 'checkbox', 
      hide: true,
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        edit_visible:false,
        type: 'text',
        valueGetter: (params) => {
            <p>123</p>
                // <Link to={`${window.location.pathname}/${params.row.id}`} className='admin-table-view'>View</Link>
        }
    }
  ];

export default { center_columns };