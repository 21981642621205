import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyDhgNSXSoUCyc0vNO2ar4-jRKzS7SZi09g",
  authDomain: "poradnya-55d71.firebaseapp.com",
  projectId: "poradnya-55d71",
  storageBucket: "poradnya-55d71.appspot.com",
  messagingSenderId: "963665633293",
  appId: "1:963665633293:web:53eb2d62a6025febceb544",
  measurementId: "G-635J1ST9DV"
};


export const app = initializeApp(firebaseConfig)
console.log(app)
export const storage = getStorage(app)




